import React from 'react'
import styled from 'styled-components';

import Header from "../../components/Header"
import Footer from "../../components/Footer"

import {
    Titulo
  } from "../../styles/styles"

const CurriculoContainer = styled.div `
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    

    p {
        text-align: center;
        width: 60vw;
        font-size: 22px;
        line-height: 1.6;
        text-align: justify;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        color: #424D87;
    }

    @media(max-width: 425px){
        margin: 0;

        p {
            font-size: 15px;
            width: 80vw;
        }
    }
`

const Fotos = styled.div `
    width: 100vw;
    display:flex;
    justify-content: space-around;
    margin: 70px 0;

    img {
        width: 400px;
        border-radius: 5px;
    }

    @media(max-width: 425px){
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;

        img {
            width: 250px;
        }
    }
`

export default function curriculo() {
    return (
        <div>
            <Header/>
                <Titulo>
                    <h2>Currículo</h2>
                    <hr/>
                </Titulo>
                <CurriculoContainer>
                    <Fotos>
                        <img src="https://mauricio-lambiasi.s3-sa-east-1.amazonaws.com/mauricioApresentando.jpg"/>
                        <p>Possui vasta experiência profissional, tendo trabalhado no Banco do Brasil, nas áreas de processos, negócios e gestão de pessoas, inclusive no exterior, além de ter atuado como educador corporativo e professor universitário.
                        <br/>
                        <br/>
                        Possui também uma sólida formação acadêmica, sendo bacharel em
                        Administração (Fundação Instituto Tecnológico de Osasco), licenciado em Psicologia Clínica (Universidad Latinoamericana de México), com certificações em Mentoring, Coaching, Psicologia Positiva, Gestão Empresarial de Qualidade de Vida no Trabalho (FIA-USP), </p>
                        </Fotos>
                    <Fotos>
                        <p>
                        além de pós-graduações em Administração Geral (FIA-USP), Finanças (PUC-RIO), Ciências Políticas (UFMG), Biologia (UFLA), MBA em Gestão Avançada de Negócios (UFMT), Mestrado em Recursos Humanos (Universidade Mackenzie), e, atualmente, doutorando em Ciências Empresariais e Sociais (Universidad de Ciencias Empresariales y Sociales de Buenos Aires).
                        <br/>
                        <br/>
                        No momento é também Diretor-Representante da Câmara de Comércio México-Brasil – Camebra.</p>
                        <img src="https://mauricio-lambiasi.s3-sa-east-1.amazonaws.com/mauricioCamebra.jpg"/>
                    </Fotos>
                </CurriculoContainer>
            <Footer/>
        </div>
    )
}
